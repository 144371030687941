import React from "react";
import Helmet from "react-helmet";
import MainLayout from "../../../../components/mainlayout";
import { navigate } from "gatsby";
import { Row, Col, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import EditOrder from "../../../../components/order/edit";
import "bootstrap/scss/bootstrap.scss";

export default function EditOrderForBulkPage(props) {
  return (
    <MainLayout>
      <Helmet title="Илгээмж засах | SB Express" defer={false} />
      <Row className="mb-3">
        <Col span={24}>
          <div style={{ position: "absolute" }}>
            <Button
              type="link"
              className="sb-arrow-back"
              onClick={() => {
                navigate(`/bulk/${props.params.id}`);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Багц илгээмж рүү буцах</span>
            </Button>
          </div>
          <div className="text-center sb-text-large">Илгээмж засах</div>
        </Col>
      </Row>
      <EditOrder orderId={props.orderId} />
    </MainLayout>
  );
}
